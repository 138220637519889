export const colors = {
	blue: {
		50: "#b18aee",
		100: "#a173eb",
		200: "#915be7",
		300: "#8144e4",
		400: "#722ce0",
		500: "#6215dd",
		600: "#5813c7",
		700: "#4e11b1",
		800: "#450f9b",
		900: "#3b0d85",
	},
	gray: {
		50: "#b18aee",
		100: "rgba(61,65,70,0.1)",
		200: "rgba(61,65,70,0.2)",
		300: "rgba(61,65,70,0.3)",
		400: "rgba(61,65,70,0.4)",
		500: "rgb(61,65,70)",
		600: "#373b3f",
		700: "#313438",
		800: "#1f2123",
		900: "#060607",
	},
	red: {
		50: "#FED7D7",
		100: "rgba(229,62,62,0.1)",
		200: "rgba(229,62,62,0.2)",
		300: "rgba(229,62,62,0.3)",
		400: "rgba(229,62,62,0.4)",
		500: "#E53E3E",
		600: "#C53030",
		700: "#9B2C2C",
		800: "#822727",
		900: "#63171B",
	},
	yellow:"#e7b007",
};
const fontWeights = {
	normal: 400,
	medium: 500,
	bold: 700,
};
const styles = {
	global: {
		"html,body": {
			//fontFamily: "'Poppins',sans-serif",
			fontSize: "sm",
			color: "gray.500",
			bgColor:"whitesmoke"
		},
		"*::placeholder": {
			color: "gray.300",
			fontSize: "0.8em"
		},
		"a": {
			_focus: {
				border:"none",
				boxShadow: "none !important"
			}
		},
	}
};
// const components  = {
// 	Button: {}
// }
const theme = { colors, styles, fontWeights };
export default theme;
